<template>
  <div>
    <van-notice-bar
      class="mb-4"
      v-if="reminder"
      wrapable
      :scrollable="false"
      :text="reminder"
    />
    <van-cell-group v-if="$store.state.date">
      <van-cell title="餐次" v-if="$store.state.date"
        >{{ $store.state.date }} {{ $store.state.orderType.name }}</van-cell
      >
      <van-cell title="床位号" is-link @click="chooseBed">{{ bed }}</van-cell>
    </van-cell-group>
    <van-cell-group class="my-4">
      <van-card
        class="bg-white"
        v-for="food in items"
        :key="food.uid"
        :title="food.name"
        :price="food | getPrice"
        :origin-price="food | getOriginPrice"
        :num="food.quantity"
        :thumb="food.image"
        :desc="food | getKouWei"
      ></van-card>
    </van-cell-group>
    <van-cell-group class="my-4">
      <van-field
        v-model="remark"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入口味等特殊要求"
      />
    </van-cell-group>
    <van-submit-bar :price="total">
      <template #button>
        <van-button
          class="submit-button"
          round
          plain
          type="danger"
          @click="continueOrder"
          >继续点餐</van-button
        >
        <van-button
          class="ml-2 submit-button"
          round
          type="danger"
          @click="submit"
          :loading="submitting"
          >提交订单</van-button
        >
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { sum } from 'lodash'
import { Toast } from 'vant'
import { makeOrder, getOpenId, getReminder } from '@/api'
import dayjs from 'dayjs'
import { isClosed, isUnStart } from '@/utils'

export default {
  data() {
    return {
      remark: '',
      submitting: false,
      reminder: ''
    }
  },

  created() {
    getReminder().then(data => {
      this.reminder = data[0].memo
    })
  },

  methods: {
    chooseBed() {
      this.$router.push({
        name: 'beds'
      })
    },

    continueOrder() {
      this.$router.go(-1)
    },

    async submit() {
      if (isClosed() || isUnStart()) {
        Toast({
          message: '门店休息中'
        })
        return
      }
      if (this.submitting) {
        return
      }
      if (this.$store.state.date && !this.bed) {
        Toast('请选择床位号')
        return
      }
      this.submitting = true

      try {
        const date = dayjs().format('YYYY-MM-DD HH:mm:ss')
        const order = await makeOrder({
          SaleMain: {
            pay_total: this.total / 100,
            item: this.items.length,
            memo: this.remark,
            lsale_id: getOpenId(),
            yyd: 'UNPAY',
            OPDATE: date,
            MODATE: date
          },
          SaleDetails: this.items.map((item, index) => {
            const real_outprice = item.mprice || item.outprice
            return {
              ...item,
              qty: item.quantity,
              real_outprice,
              real_amount: real_outprice * item.quantity,
              item: index,
              kw: item.specs?.map(spec => `<${spec.kwname}>`).join(''),
              OPDATE: date,
              MODATE: date
            }
          })
        })
        this.remark = ''
        this.$store.dispatch('afterCheckout')
        this.$store.dispatch('clearCart')
        this.$router.replace({
          name: 'order',
          query: {
            sale_id: order.SaleMain.sale_id,
            afterCheckout: true
          }
        })
      } catch (err) {
        Toast({
          message: err
        })
      } finally {
        this.submitting = false
      }
    }
  },

  computed: {
    items() {
      return this.$store.getters.cartItems
    },

    total() {
      return sum(
        this.items.map(item => {
          return (item.mprice || item.outprice) * 100 * item.quantity
        })
      )
    },

    bed() {
      return this.$store.state.bed
    }
  }
}
</script>
<style lang="scss" scoped>
.submit-button {
  width: 110px;
  height: 40px;
}
</style>